<template>
  <div class="loading-payment-check">
    <!-- modal terms -->
    <div>
      <b-modal
        id="loading-payment-check"
        hide-header
        hide-footer
        no-close-on-backdrop
      >
        <div class="text-center" style="margin: 10px">
          <div class="lds-roller">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        <div class="d-block text-center">
          <div>{{ status }}</div>
          <div>{{ message }}</div>
        </div>
      </b-modal>
    </div>
    <!-- modal Thanks -->
    <div class="warning-payment">
      <div class="modal-warning">
        <div class="modal_content">
          <div style="display: flex">
            <img src="@/assets/img/warning_payment.png" />
          </div>
          <p>お申し込みありがとうございます！</p>
          <p v-if="isManualApproval">管理者による決済状況を確認しています。しばらくお待ちください。</p>
          <p>ページに移動中です。</p>
          <p>すぐにページが移動しない場合は、</p>
          <p>
            <a :href="link">こちらのリンク</a>をクリックしてください。
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Constants } from "../../utils/constants";
import { Api } from "../../utils/http-common.js";
import { Urls } from "../../utils/urls";

export default {

  name: "external-payment-thanks",
  data() {
    return {
      status: '読み込み中です。',
      message: 'しばらく、そのままでお待ちください。',
      isManualApproval : true,
      shop_id: localStorage.getItem(Constants.SHOP_ID),
      external_payment_token: localStorage.getItem(Constants.EXTERNAL_PAYMENT_TOKEN),
      link: "",
    };
  },
  async mounted() {
    this.status = '決済の最終確認を行っております。';
    this.$bvModal.show('loading-payment-check');
    const data = {
      shop_id: this.shop_id,
      identifier: this.$route.params.identifier,
      external_payment_token: this.external_payment_token,
      external_payment_history_id: localStorage.getItem('external_payment_history_id'),
      referrer: document.referrer,
      //log_data
      title: this.$route.meta.title,
      url: window.location.origin + this.$route.fullPath,
    }
    await Api.userRequestServer
      .post(`/${Urls.EXTERNAL_PAYMENT_CHECK}`, data)
      .then( res => {
        if (res.data.success) {
          const order = res.data.data.order;
          this.$bvModal.hide('loading-payment-check');
          localStorage.removeItem('external_payment_token');
          localStorage.removeItem('external_payment_history_id');
          document.getElementsByClassName("warning-payment")[0].style.display = "block";
          if (order.status_order === Constants.STATUS_ORDER.accepted) {
            this.isManualApproval = false;
          }
          this.link = order.sale_info[0].externalPaymentThankUrl ?? (
            this.$route.params.shopId ? window.location.origin + '/shop/' + this.shop_id : window.location.origin
          );
          setTimeout(() => {
            if (order.sale_info[0].externalPaymentThankUrl) {
              location.href = order.sale_info[0].externalPaymentThankUrl;
            } else {
              this.$router.push({
                name: this.$route.params.shopId ? "library" : "library domain",
              });
            }
          }, 11000);
        } else {
          this.status = res.data.message + 'が発生しました。';
          this.message = 'システム管理者に連絡してください。';
        }
      })
      .catch( res => console.log(res));
  },
};
</script>
<style lang="scss">
.warning-payment {
  background: #d6d6d669;
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  display: none;
  .modal-warning {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    padding: 10px;
  }
  .modal_content {
    margin: auto;
    background-color: white;
    border-radius: 10px;
    padding: 5px;
    max-width: 900px;
  }
  .text-warning {
    margin-left: auto;
    margin-right: auto;
    color: red !important;
    font-size: 16px;
    font-weight: 800;
    padding: 15px;
    border-radius: 8px;
    background-color: white;
    text-align: center;
  }
  img {
    width: 40%;
    height: 40%;
    min-height: 200px;
    min-width: 200px;
    margin: auto;
  }
  p {
    text-align: center;
    font-size: 24px;
    @media (max-width: 767px) {
      font-size: 16px;
    }
  }
}
</style>
